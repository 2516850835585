@import '../../../../../or_base/scss/theme-bootstrap';

.custom-kit-tray {
  left: 0;
  overflow: hidden;
  position: fixed;
  width: 100%;
  z-index: 4;
}

.custom-kit-tray .slick-slider {
  height: 100%;
}

.custom-kit-tray .slick-list {
  height: 100%;
}

.custom-kit-tray .slick-track {
  height: 100%;
}

.custom-kit-tray .slick-slide {
  height: 100%;
}

.custom-kit-tray__content {
  display: -ms-flexbox;
  display: flex;
}

.gift-thumb__product-name {
  color: $color-light-green;
  text-decoration: none;
  font-family: $font--futura-book;
  text-transform: uppercase;
  letter-spacing: 0;
  font-size: 9px;
  line-height: 11px;
  margin-bottom: 5px;
  max-height: 22px; /* 2 times the line-height to allow max two lines of text */
  overflow: hidden;
}

.gift-thumb__price {
  color: $color-darker-gray;
  text-decoration: none;
  font-size: 18px;
}

.gift-thumb__price {
  font-style: italic;
  position: absolute;
  bottom: 15px;
}

.gift-thumb__product_sub-name,
.gift-info__total,
.gift-info__list--item {
  font-family: $font--futura-demi;
  text-transform: uppercase;
  letter-spacing: 0.05em;
}

.gift-info__total,
.gift-info__list--item {
  line-height: 24px;
  font-size: 22px;
}

.gift-thumb__product_sub-name {
  max-height: 68px; /* 4 times the line-height to allow max four lines of text */
  overflow: hidden;
  font-size: 16px;
  line-height: 16px;
}

.custom-kit-tray__wrapper {
  background-color: $color-white;
  border-bottom: 1px solid $color-light-gray;
  margin: 0 auto;
  position: relative;
  max-height: 100%;
}
/* TRAY INFO AREA */

.custom-kit-tray__info {
  background-color: $color-light-green;
  height: auto;
  width: 100%;
}

.custom-kit-tray__info .gift-info__list {
  height: 100%;
  padding: 20px;
}

.custom-kit-tray__info .gift-info__list--list {
  -ms-flex-align: center;
  align-items: center;
  display: -ms-flexbox;
  display: flex;
  height: 100%;
  line-height: 18px;
}

.custom-kit-tray__info .gift-info__list--item {
  color: $color-white;
  margin-bottom: 0;
  list-style-type: none;
  text-align: left;
}

.custom-kit-tray__info .gift-info__total {
  -ms-flex-positive: 1;
  flex-grow: 1;
}

.custom-kit-tray__info .gift-info__total--link {
  color: $color-white;
}

.custom-kit-tray__info .gift-link__link {
  color: $color-dark-gray;
  background: $color-white;
  -webkit-box-shadow: 0 0 0 1px $color-darker-gray;
  -moz-box-shadow: 0 0 0 1px $color-darker-gray;
  box-shadow: 0 0 0 1px $color-white;
  border-color: $color-light-green;
}
/* TRAY THUMB AREA (PC Only) */

.custom-kit-tray__thumbs {
  -ms-flex-positive: 1;
  flex-grow: 1;
  height: 110px;
  position: relative;
  padding: 0 2em;
  overflow: hidden;
  width: 70%;
}

.custom-kit-tray__thumbs li {
  height: 100%;
}

.custom-kit-tray__thumbs .gift-thumbs__item {
  float: left;
  position: relative;
  padding: 10px 0;
}

.custom-kit-tray__thumbs .gift-thumb__delete {
  cursor: pointer;
  content: '';
  background: $color-light-green;
  width: 15px;
  height: 15px;
  border-radius: 100px;
  position: absolute;
}

.custom-kit-tray__thumbs .gift-thumb__delete::before,
.custom-kit-tray__thumbs .gift-thumb__delete::after {
  content: '';
  position: absolute;
  left: 7px;
  height: 60%;
  width: 1px;
  background-color: $color-white;
  top: 3px;
}

.custom-kit-tray__thumbs .gift-thumb__delete::before {
  transform: rotate(45deg);
}

.custom-kit-tray__thumbs .gift-thumb__delete::after {
  transform: rotate(-45deg);
}

.custom-kit-tray__thumbs .gift-thumbs__item:hover .gift-thumb__delete {
  display: block;
}

.gift-thumbs__item .gift-thumb__thumb {
  float: left;
  margin-right: 10px;
  width: 70px;
}

.gift-thumbs__item .gift-thumb__details {
  float: left;
  width: calc(100% - 90px);
  text-align: left;
}

.custom-kit-tray .slick-prev.slick-arrow {
  left: -25px;
  -webkit-transform: rotate(-135deg);
  transform: rotate(135deg);
}

.custom-kit-tray .slick-next.slick-arrow {
  right: -25px;
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
}

.custom-kit-tray .slick-arrow {
  height: 10px;
  margin: -27px 0 0 0;
  position: absolute;
  text-indent: -9999px;
  top: 50%;
  -webkit-transition: none;
  -moz-transition: none;
  transition: none;
  width: 10px;
  margin-top: -5px;
  border-width: 0 2px 2px 0;
  border-color: $color-darker-gray;
  border-style: solid;
}
/* Tray Tabs */
.custom-kit-tabs {
  width: 100%;
  left: 0;
  bottom: 14.5em;
  position: fixed;
  z-index: 102;
}

.custom-kit-tabs--review {
  width: 100%;
  display: none;
}

.custom-kit-tabs--review .review-tab__tab {
  margin: 0 auto;
  padding: 2em 2em;
  background-color: $color-black;
  color: $color-white;
  text-transform: uppercase;
  width: 45%;
  text-align: center;
}

.custom-kit-tabs--scroll {
  background: url('/media/enhanced_gifting/scroll_arrow_up.png') no-repeat scroll 50% 50%
    transparent;
  position: absolute;
  bottom: auto;
  top: 0;
  right: 0;
  line-height: 2.5em;
  background-color: $color-black;
  color: $color-white;
  text-transform: uppercase;
  width: 5%;
  max-width: 2.5em;
  text-align: center;
  font-size: 2.1em;
  text-decoration: underline;
  height: 2.5em;
  cursor: pointer;
}
/* Desktop */

@media only screen and (min-width: 768px) {
  .custom-kit-tray__info {
    height: 110px;
    min-width: 402px;
    width: 30%;
  }
}

@media only screen and (max-width: 736px) {
  .custom-kit-tray {
    bottom: 40px;
    z-index: 20;
  }
  .custom-kit-tray__thumbs {
    display: none;
  }
  .custom-kit-tray__controls {
    display: none;
  }
  /* Tray Mobile Item List */
  .gift-info__items {
    position: absolute;
    bottom: 6em;
    left: 0;
    background-color: $color-white;
    width: 100%;
    border-top: 1px solid $color-light-gray;
    z-index: -1;
    padding-bottom: 2em;
  }
  .gift-info__items .gift-items__close {
    background: rgba(0, 0, 0, 0) url('/sites/jomalone/themes/jm_base/img/arrow-down-black.png')
      no-repeat scroll 90% 80%;
    width: 100%;
    padding: 0.75em 2em;
    text-align: right;
    height: 2em;
  }
  .gift-info__items .gift-items__list {
    padding: 0;
    margin: 0;
  }
  .gift-info__items .gift-items__item {
    list-style-type: none;
    margin: 0.5em;
    padding: 0.5em;
  }
  .gift-info__items .gift-items__item--name {
    display: inline-block;
    width: 80%;
  }
  .gift-info__items .gift-items__item--remove {
    display: inline-block;
    width: 15%;
    text-align: right;
    text-decoration: underline;
  }
  .gift-items__item--engraved .gift-items__item--engrave-name {
    width: 80%;
    display: inline-block;
    margin-top: 0.5em;
  }
  .custom-kit-tabs {
    bottom: 0;
    z-index: 100;
  }
  .custom-kit-tabs--scroll {
    bottom: 0;
    top: auto;
    width: 15%;
  }
  /* using important here to override rule in _power_review.scss, which also uses important */
  .product-grid-wrapper .product-brief__header .review-snippet {
    display: inline-block !important;
  }
}
